.user {
    color: var(--color-green);
}

.light .user {
    color: var(--color-dark-green);
}

.tilde {
    color: var(--color-blue);

}

.light .tilde {
    color: var(--color-dark-blue);
}

.cursor {
    color: var(--color-white)
}

.light .cursor {
    color: var(--color-black);
}

.empty-span {
    font-size: 0;
    line-height: 1rem;
}


.file {
    color: var(--color-white);
}

.light .file {
    color: var(--color-black);
}

.folder {
    color: var(--color-blue);
}

.light .folder {
    color: var(--color-dark-blue);
}

span {
    white-space: break-spaces;
}

a {
    color: var(--color-blue);
}

.line:has(.textarea-touch)  {
    display: flex;
}

a:hover {
    color: var(--color-white);
}

.light a:hover {
    color: var(--color-black);
}


.textarea-touch {
    border: none;
    resize: none;
    outline: none;
    background-color: var(--color-black);
    color: var(--color-white);
    margin: 0;
    padding: 0;
    width: 100%;
    text-transform: lowercase;
}

/* Define the animation */
@keyframes pulsating {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

.animated-textarea:not(:focus) {
    width: 50%;
    background-color: var(--color-white);
    animation: pulsating 2s ease 0s infinite normal forwards;
}