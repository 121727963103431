:root {
  --color-black: #111;
  --color-white: #eee;
  --color-red: #EB9BBD;
  --color-yellow: #F0EBAB;
  --color-green: #C7E38D;
  --color-dark-green: #03C03C;
  --color-green-matrix: #00FF41;
  --color-purple: #978BC4;
  --color-blue: #83B3E0;
  --color-dark-blue: #579ABE;
}

body {
  margin: 0;
  font-family: 'Ubuntu Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--color-white);
  color: var(--color-black);
}

body.dark {
  background-color: var(--color-black);
  color: var(--color-white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

html::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}


.select-mode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
  font-family: 'Ubuntu Mono', monospace;
  gap: 2rem;
}

.select-button {
  font-size: 3rem;
  background-color: var(--color-white);
  color: var(--color-black);
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid  var(--color-black);
}

.select-button:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transition: transform 0.4s ease-out;

  background-color: var(--color-black);
  color: var(--color-white);
  border: 2px solid  var(--color-white);
}








